div.howItWorks{
    div.overlayModal {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div.container {
        z-index: 999;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 50%;
        // bottom: 0;
        transform: translate(-50%, -50%);
        background-color: var(--modal-bg-color);
        width: 60%;
        margin: 0 auto;
        display: flex;
        padding: 20px;
        flex-direction: column;
        @media all and (max-width: 1200px) {
          padding: 30px;
          width: 80%;
        }
        @media all and (max-width: 980px) {
            width: 80%;
          }
        @media all and (max-width: 768px) {
          width: 80%;
        }
        @media all and (max-width: 640px) {
          width: 90%;
          padding: 20px 10px;
        }
        div.closeIconContainer {
          position: absolute;
          inset-inline-end: 5px;
          top: 0;
          cursor: pointer;
          svg{
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
}
