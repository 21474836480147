/* Demo */
// body {
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: linear-gradient(#ecf0f1, #fff);
// }

/* Animation */


div.loadingContent{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-direction: column;
  div.loadingOverlay{
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color:  var(--white-color);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div.loading-container {
      width: 256px;
      height: 256px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 99999999;

    }
    
    .loading {
      width: 64px;
      height: 64px;
      position: absolute;
      top: 36%;
      inset-inline-start: 36%;
      transform: rotate(45deg);  
    }
    
    .loading div {
      width: 20px;
    height: 20px;
      position: absolute;
    }
    
    .l1 div, .l2 div, .l3 div, .l4 div {
      width: 100%;
      height: 100%;
      background-color: #3498db;
    }
    
    .l1 { transform: translate(0,0); }
    .l2 { transform: translate(0,32px); }
    .l3 { transform: translate(32px,0px); }
    .l4 { transform: translate(32px,32px); }
    
    @keyframes rot1 {
      0%{ transform: rotate(0deg); }
      40%{ transform: rotate(0deg); }
      50%{ transform: rotate(0deg); }
      60%{ transform: rotate(90deg); }
      100%{ transform: rotate(90deg); }
    }
    
    @keyframes rot2 {
      0%{ transform: rotate(0deg); }
      40%{ transform: rotate(0deg); }
      50%{ transform: rotate(0deg); }
      60%{ transform: rotate(-90deg); }
      100%{ transform: rotate(-90deg); }
    }
    
    @keyframes rot3 {
      0%{ transform: rotate(45deg); }
      35%{ transform: rotate(45deg); }
      65%{ transform: rotate(405deg); }
      100%{ transform: rotate(405deg); }
    }
    
    @keyframes l1-rise {
      0%{ transform: translate(0px,0px); }
      30%{ transform: translate(-8px, -8px); }
      70%{ transform: translate(-8px, -8px); }
      100%{ transform: translate(0px, 0px); }
    }
    
    @keyframes l2-rise {
      0%{ transform: translate(0,32px); }
      30%{ transform: translate(-8px, 40px); }
      70%{ transform: translate(-8px, 40px); }
      100%{ transform: translate(0,32px); }
    }
    
    @keyframes l3-rise {
      0%{ transform: translate(32px, 0px); }
      30%{ transform: translate(40px, -8px); }
      70%{ transform: translate(40px, -8px); }
      100%{ transform: translate(32px, 0px); }
    }
    
    @keyframes l4-rise {
      0%{ transform: translate(32px,32px); }
      30%{ transform: translate(40px, 40px); }
      70%{ transform: translate(40px, 40px); }
      100%{ transform: translate(32px,32px); }
    }
    
    @keyframes color {
      0%{ background-color: #3498db; }
      50%{ background-color: #2980b9; }
      100%{ background-color: #3498db; }
    }
    
    .l1 { animation: l1-rise 1s ease 0s infinite; }
    .l2 { animation: l2-rise 1s ease 0s infinite; }
    .l3 { animation: l3-rise 1s ease 0s infinite; }
    .l4 { animation: l4-rise 1s ease 0s infinite; }
    
    .l1 div, .l4 div { animation: rot1 1s ease 0s infinite, color 1s linear 0s infinite; }
    .l2 div, .l3 div { animation: rot2 1s ease 0s infinite, color 1s linear 0s infinite; }
   
    
}
