div.podcastModal{
    .rhap_container{
        background-color: #303030 !important;
        border-radius: 5px;
    }
    .rhap_time{
        color: #a29b9b !important;
    }
    div.overlayModal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.closeIconContainer{
        position: fixed;
        top: 20px;
        inset-inline-end: 30px;
        z-index: 999;
        cursor: pointer;
        svg{
            width: 20px !important;
            height: 20px !important;
        }
    }
    div.contents{
        z-index: 999;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 50%;
        // bottom: 0;
        transform: translate(-50%,-50%);
        background-color: var(--bg-color);
        width: 40%;
        margin: 0 auto;
        // padding: 30px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        @media all and (max-width:1400px){
            width: 30%;
        }
        @media all and (max-width:1200px){
            width: 60%;
        }
        @media all and (max-width:768px){
            width: 75%;
        }
        @media all and (max-width:480px){
            width: 90%;
        }
        div.podcastContainer{
            display: flex;
            align-items: center;
            flex-direction: column;
            @media all and (max-width:980px){
            }
            div.left{
                // margin-bottom: 10px;
                width: 100%;
                @media all and (max-width:980px){
                    width: 100%;
                    margin-inline-end: 0;
                }
                div.imageContainer{
                  width: 100%;
                  img{
                    width: 100%;
                    display: block;
                    border-radius: 5px;
                  }
                     
                  }
            }
            div.right{
                width: 100%;
                @media all and (max-width:980px){
                    width: 100%;
                }
                h1.title{
                  color: var(--text-color);
                  font-size: 20px;
                  margin-bottom: 10px;
                }
                div.audioContainer{
                    @media all and (max-width:640px){
                        width: 100%;
                    }
                    audio{
                        width: 100%;
                        display: block;
                    }
                }

            }
        }
       
    }
}
