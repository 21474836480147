div.accountSettings {
  padding: 80px 5px ;
  position: relative;
  display: flex;
  background-color: var(--secondary-bg-color);
  @media all and (max-width:768px){
	flex-direction: column;
}
  span.error {
    font-size: 14px;
    color: #e72a31;
    display: block;
    margin-top: 10px;
    font-weight: normal !important;
  }

  div.leftContainer {
    width: 18%;
    position: relative;
	margin-inline-end: 20px;
	@media all and (max-width:1300px){
		width: 25%;
	}
	@media all and (max-width:980px){
		width: 31%;
	}
	@media all and (max-width:768px){
		width: 60%;
		margin: 0 auto;
	}
    ul.tabs {
      position: fixed;
	  @media all and (max-width:768px){
		position: unset;
	}
      li {
        font-size: 13px;
        color: var(--text-color);
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 1px solid #c8c8c8;
        margin-bottom: 10px;
		cursor: pointer;
		&.active{
			color: var(--button-hover);
		}
      }
    }
  }

  div.rightContainer {
    width: 80%;
	@media all and (max-width:1300px){
		width: 70%;
	}
	@media all and (max-width:980px){
		width: 65%;
	}
	@media all and (max-width:768px){
		width: 90%;
		margin: 0 auto;
	}
    div.top {
      background-color: var(--white-color);
      padding: 8px 15px;
      margin-bottom: 10px;
      h1 {
        color: var(--text-color);
        font-size: 17px;
      }
    }
    div.bottom {
      background-color: var(--white-color);
      padding: 30px 25px;
      div.profileSection {
        padding-bottom: 40px;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 20px;
        div.imageContainer {
          width: 200px;
          height: 200px;
          margin: 0 auto;
          text-align: center;
          img {
            width: 100%;
            display: block;
            border-radius: 50%;
            object-fit: cover;
            height: 100%;
          }
          input {
            display: none;
          }
          label {
            font-size: 13px;
            color: var(--text-color);
			cursor: pointer;
			text-transform: uppercase;
			font-weight: 700;
			&:hover{
				color: var(--button-hover);
			}
          }
        }
      }
      div.contactDetails {
        div.row {
          padding-bottom: 25px;
          border-bottom: 1px solid var(--border-color);
          margin-bottom: 20px;
          h1.heading {
            font-size: 16px;
            color: var(--text-color);
            margin-bottom: 20px;
          }
          div.inputField {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-bottom: 20px;
			@media all and (max-width:480px){
				width: 100%;
			}
            &.noMargin {
              margin-bottom: 0;
            }
            label {
              font-size: 12px;
              color: var(--text-color);
              margin-bottom: 10px;
            }
            input {
              width: 100%;
              background-color: transparent;
              outline: none;
              border: 1px solid #c8c8c8;
              padding: 10px;
              &:focus {
                border: 2px solid var(--black-color);
                border-radius: 5px;
              }
            }
            select {
              width: 100%;
              background-color: transparent;
              outline: none;
              border: 1px solid #c8c8c8;
              padding: 10px;
              &:focus {
                border: 2px solid var(--black-color);
                border-radius: 5px;
              }
            }
          }
		  div.newsletterSubscribe{
			display: flex;
			align-items: center;
			margin-top: 10px;
			input{
				margin-inline-end: 6px;
				width: 15px;
				height: 15px;
			}
			label{
				color: var(--text-color);
				font-size: 13px;
			}
		  }
          div.buttonContainer {
            button {
              background-color: var(--button-hover);
              padding: 8px 12px;
              color: var(--white-color);
              font-size: 13px;
              cursor: pointer;
            }
            button:disabled,
            button[disabled] {
              border: 1px solid #999999;
              background-color: #cccccc;
              color: var(--text-color);
			  cursor: default;
            }
          }
        }
      }
    }
	div.planDetails{
		background-color: var(--white-color);
      padding: 30px 25px;
	  h1.heading{
		font-size: 14px;
		text-transform: uppercase;
		color: var(--text-color);
		margin-bottom: 20px;
	  }
	  h2.planName{
		font-size: 12px;
		color: var(--text-color);
		margin-bottom: 20px;
	  }
	  div.buttonContainer {
		margin-bottom: 20px;
		button {
		  background-color: var(--button-hover);
		  padding: 8px 12px;
		  color: var(--white-color);
		  font-size: 13px;
		  cursor: pointer;
		}
	  }
	  span.link{
		display: block;
		font-size: 12px;
		color: var(--button-hover);
		font-weight: 700;
		margin-bottom: 10px;
		cursor: pointer;
	  }
	}
	div.streamingActivity{
		background-color: var(--white-color);
      padding: 30px 25px;
		@media all and (max-width:1200px){
			width: 100%;
		}
		
		p{
			font-size: 18px;
			color: var(--text-color);
			margin-bottom: 30px;
		}
		ul.recentDeviceContainer{
			li{
				border-bottom: 1px dashed var(--heading-color);
				padding-bottom: 30px;
				margin-bottom: 30px;
				span{
					color: var(--text-color);
					display: block;
					font-size: 14px;
					&:first-child{
						margin-bottom: 10px;
					}
				}
			}
		}
		div.buttonContainer { 
			display: flex;
		padding: 18px 20px;
		border-radius: 4px;
		align-items: center;
		margin-bottom: 15px;
		cursor: pointer;
		transition: 0.2s ease-in-out;
		justify-content: center;
		position: relative;
		width: 20rem;
		margin: 30px auto 0;
		@media all and (max-width:640px){
			width: 15rem;
		}
		@media all and (max-width:480px){
			width: 10rem;
		}
		&:hover{
			div.background{
	
				transform: scale(1.0476190476,1.0357142857);
			}
		}
			div.background{
				background: var(--text-hover);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 4px;
				transition: 0.1s ease-in-out;
				z-index: 1;
	
			}
			button { 
				background: transparent;
				font-size: 15px;
				color: var( --white-color);
				font-weight: 600;
				position: relative;
				z-index: 2;
				cursor: pointer;
	
	
			}
		}
	}
  }
}
