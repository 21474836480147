div.paymentScreen { 
    padding: 100px 0;
    background-color: var(--sub-bg-color);
    .paypal-button-container {
        min-width: 326px !important;
    max-width: 284px !important;
    }
	div.wrapper { 
        @media all and (max-width:640px){
            width: 90%;
        }
		h1.heading { 
            text-align: center;
            font-size: 30px;
            color: var(--white-color);
            margin-bottom: 30px;
            text-transform: uppercase;
            @media all and (max-width:480px){
                font-size: 25px;
            }
		}
        div.mainContainer{
            width: 80%;
            margin: 0 auto;
            display: flex;
            // align-items: center;
            @media all and (max-width:980px){
                flex-direction: column;
                width: 80%;
            }
            @media all and (max-width:640px){
                width: 100%;
            }
            div.left{
                width: 60%;
                @media all and (max-width:980px){
                    width: 100%;
                }
                div.couponCodeContainer { 
        
                    label { 
                        font-size: 20px;
                        display: block;
                        color: var(--white-color);
                    }
        
                    div.inputContainer { 
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                        width: 35rem;
                        @media all and (max-width:768px){
                            width: 30rem;
                        }
                        @media all and (max-width:640px){
                            width: 100%;
                            flex-direction: column;
    
                        }
                        input { 
                            outline: none;
                                font-size: 14px;
                                background: #000;
                                text-align: start;
                              padding: 20px 15px;
                              border: 1px solid var(--text-color);
                              caret-color: var(--white-color);
                              display: block;
                              width: 100%;
                              color: var(--white-color);
                              margin-inline-end: 20px;
                              &:focus{
                                  border: 1px solid var(--white-color);
                              }
                              @media all and (max-width:640px){
                                  padding: 16px 15px;
                                margin-bottom: 20px;
                                margin-inline-end: 0;
    
                              }
                              @media all and (max-width:480px){
                            }
                        }
        
                        div.buttonContainer { 
                            display: flex;
                        padding: 18px 20px;
                        align-items: center;
                        cursor: pointer;
                        transition: 0.2s ease-in-out;
                        justify-content: center;
                        position: relative;
                        width: 15rem;
                        margin: 0 auto;
                        @media all and (max-width:640px){
                            width: 15rem;
                        padding: 16px 20px;
    
                        }
                        @media all and (max-width:480px){
                            width: 10rem;
                        }
                        &:hover{
                            div.background{
            
                                transform: scale(1.0476190476,1.0357142857);
                            }
                        }
                            div.background{
                                background: var(--button-hover);
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                transition: 0.1s ease-in-out;
                                z-index: 1;
            
                            }
                            button { 
                                background: transparent;
                                font-size: 15px;
                                color: var( --white-color);
                                font-weight: 600;
                                position: relative;
                                z-index: 2;
                                cursor: pointer;
            
            
                            }
                        }
                    }
                    span.success{
                        color: var(--success-color);
                    }
                }
        
                div.paymentGatewayContainer { 
                    margin-top: 30px;
                    margin-bottom: 20px;
                    h2.heading { 
                        font-size: 20px;
                        color: var(--white-color);
                        font-weight: 400;
                    }
        
                    ul.gateways { 
                        display: flex;
                        flex-wrap: wrap;
                        @media all and (max-width:480px){
                            
                        }
                        li { 
                            margin: 10px 10px 0px 0px;
                            width: 90px;
    
                            &:last-child{
                                margin-inline-end: 0;
                            }
                            @media all and (max-width:640px){
                                width: 70px;
        
                            }
                            @media all and (max-width:480px){
                                width: 70px;
        
                                
                            }
                            div.imageContainer { 
                                width: 100%;
                                transition: 0.3s ease-in-out;
                                cursor: pointer;
                                &:hover{
                                    transform: scale(1.1);
                                }
                                img { 
                                    width: 100%;
                                    display: block;
                                }
                            }
                        }
                    }
                }

                
            }
            div.right{
                width: 40%;
                border-inline-start: 2px solid var(--border-color);
                padding: 80px 40px 0;
                @media all and (max-width:980px){
                    width: 90%;
                    padding: 40px 0 0;
                    border-top: 2px solid var(--border-color);
                    border-inline-start:none;
                }
                @media all and (max-width:768px){
                    width: 100%;
                }
               iframe{
                z-index: 2 !important;
               }
            }
        }
	}
}
