div.footerContainer { 
    padding: 30px 0;
    position: relative;
    z-index: 9;
    background-color: var(--default-bg-color);
    border-top: 1px solid var(--default-border-color);
    div.wrapper{
        width: 90%;
    }
	div.topContainer { 
        display: flex;
        justify-content: space-between;
		div.left { 
            width: 20%;
            border-bottom: 1px solid var(--default-border-color);
            padding-bottom: 20px;
			div.logoContainer { 
                width: 100px;
				img { 
                    display: block;
                    width: 100%;

                    &.jwm{
                        width: 60%;
                        @media(max-width: 480px){
                            width: 50%;
                        }
                        @media(max-width: 480px){
                            width: 45%;
                        }
                    }
				}
			}
		}

		div.right { 
            width: 20%;
            &.jwm{
                width: 30%;
            }
            border-bottom: 1px solid var(--default-border-color);
            padding-bottom: 20px;
            @media all and (max-width:640px){
                width: 35%;
            }
            img{
                display: none;
                padding-bottom: 10px;
                @media(max-width: 980px){
                    position: relative;
                    display: block;
                    inset-inline-end: 60%;
                    width: 250px;
                }
                @media(max-width: 480px){
                    width: 200px;
                }
                @media(max-width: 360px){
                    width: 150px;
                }
            }
			ul.social { 
                display: flex;
                align-items: center;
				li { 
                    margin-inline-end: 10px;
                    &:last-child{
                        margin-inline-end: 0;
                    }
                    
                    &:hover{
                        svg{
                            opacity: 0.6;
                            color: var( --default-text-hover-color);
                        }
                    }
					svg { 
                        width: 20px !important;
                        height: 20px !important;
                        color: var(--grey-color);
                        transition: 0.3s ease-in-out;
                        cursor: pointer;
					}
                    img{
                        display: block;
                        width: 350px !important;
                        @media(max-width: 1600px){
                            width: 300px !important;
                        }
                        @media(max-width: 1200px){
                            width: 250px !important;
                        }
                        @media(max-width: 980px){
                            display: none;
                        }
                    }
				}
			}
		}
	}

	div.centerContainer { 

		div.informationContainer { 
            display: flex;
            flex-wrap: wrap;
            padding: 40px 0;
			div.items { 
                width: 20%;
                color: var(--white-color);
                @media all and (max-width:768px){
                    width: 25%;
                    margin-bottom: 20px;
                }
                @media all and (max-width:640px){
                    width: 33%;
                }
				h6.heading { 
                    font-size: 12px;
                    color: var(--text-color);
                    margin-bottom: 10px;
				}

				ul { 

					li { 
                    
                    span{
                        font-size: 12px;
                        cursor: pointer;
                        transition: 0.3s ease-in-out;
                        @media all and (max-width:360px){
                            font-size: 13px;
                        }
                        &:hover{
                            opacity: 0.6;
                            color: var( --default-text-hover-color);
                        }
                    }
                    

					}
				}
			}
		}
	}

	div.bottomContainer { 
        border-top: 1px solid var(--default-border-color);
        padding: 40px 0;
		div.stores { 
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
			svg { 
                margin-inline-end: 10px;
                width: 120px !important;
                transition: 0.3s ease-in-out;
                cursor: pointer;
                &:last-child{
                    margin-inline-end: 0;
                }
                &:hover{
                    opacity: 0.8;
                }
			}

			
		}

		p { 
            text-align: center;
            font-size: 12px;
            color: var(--grey-color);
            opacity: 0.7;
		}
        div.typeOfPayments{
            color: var(--grey-color) !important;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            i{
                margin-inline-end: 6px;
                &:last-child{
                    margin-inline-end: 0;
                }
            }
        }
	}
}
