div.contactSupport {
  padding: 130px 0;
  background-color: var(--secondary-bg-color);
  h1.heading {
    font-size: 28px;
    margin-bottom: 30px;
    color: var(--text-color);
    text-align: center;
    @media all and (max-width: 640px) {
      font-size: 28px;
    }
  }
  div.formContainer {
    width: 50%;
    margin: 0 auto;
    @media all and (max-width:980px){
      width: 100%;
    }
    form {
      div.full {
        margin-bottom: 20px;
        input,
        select {
          color: var(--white-color) !important;
          
          outline: none;
          border-radius: 5px;
          font-size: 14px;
          background: transparent;
          text-align: start;
          padding: 20px 15px;
          border: 1px solid var(--text-color);
          caret-color: var(--white-color);
          display: block;
          width: 100%;
          color: var(--white-color);
          option {
            color: #000 !important;
          }
          &:focus {
            border: 1px solid var(--white-color);
          }
          @media all and (max-width: 640px) {
            padding: 16px 15px;
          }
        }

        label {
          color: var(--text-color);
          font-size: 16px;
          display: inline-block;
          margin-bottom: 10px;
        }
        &.message{
            display: flex;
            flex-direction: column;
            textarea {
                height: 150px;
                outline: none;
          border-radius: 5px;
          font-size: 14px;
          background: transparent;
          caret-color: var(--text-color);
          color: var(--white-color);
          padding: 20px 15px;
          &:focus {
            border: 1px solid var(--white-color);
          }


            }
        }
       
        div.buttonContainer {
          margin-bottom: 20px;
          text-align: center;
          button {
            background-color: var(--button-hover);
            padding: 8px 12px;
            color: var(--white-color);
            font-size: 13px;
            cursor: pointer;
          }
          }
      }
    }
  }
}
