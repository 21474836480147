div.showsRow {
  padding: 40px 60px;
  position: relative;
  z-index: 3;
  @media all and (max-width:768px){
    padding: 30px 30px;
  } 
  h1.heading {
    font-size: 25px;
    color: var(--text-color);
    margin-bottom: 20px;
  }
  div.seasonContainer{
    border: 2px solid var( --white-color);
    border-radius: 8px;
    width: 15rem;
    margin-bottom: 20px;
    margin-left: 5px;
    background: transparent;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: var(--text-color);
    cursor: pointer;
    @media all and (max-width:480px){
      width: 100%;
    }
    svg{
      width: 26px !important;
      height: 26px !important;
      transition: 0.3s ease-in-out;
      &.active{
        transform: rotate(-180deg);
      }
    }
    &.zIndexController{
      div.listContainer{
        z-index: 9;
      }
    }
    div.listContainer{
      
      height: 200px;
      overflow-y: scroll;
      position: absolute;
      top: 55px;
      width: 100%;
      left: 0;
      right: 0;
      z-index: -1;
      -ms-overflow-style: none ;  /* IE and Edge */
      scrollbar-width: none ;  /* Firefox */
      &::-webkit-scrollbar {
        display: none ;
      }
      &.scroll{
      -ms-overflow-style: unset ;  /* IE and Edge */

        scrollbar-width: unset ;  /* Firefox */
      &::-webkit-scrollbar {
        display: block ;
      }
      &::-webkit-scrollbar{
        width: 5px;
        border-radius: 30px;
      }
      &::-webkit-scrollbar-thumb{
        background-color: var(--bg-color);
        border-radius: 30px;
      }
      &::-webkit-scrollbar-track{
        background-color: var(--grey-color);
      }
      }
      cursor: pointer;
    }
    div.list{   
      // border: 1px solid var(--border-color);
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      display: none;
     
      &.active{
        display: block;

      }
      div.item{
        width: 100%;
        padding: 8px 10px;
        border-bottom: 1px solid var(--grey-color);
        background-color: var(--bg-color);
        &:hover {
          border-color: var(--white-color);
        
          background-color:var(--secondary-bg-color);
          color: var(--white-color);
        }
        &:last-child{

          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        span{
          font-size: 16px;
          color: var(--text-color);
        }
      }

    }
    &:hover {
      border-color: var(--white-color);
      // background-color: var(--grey-color);
      background-color: #cac6c6;
      color: var(--white-color);
    }
  }
  .swiper{

  }
  .portrait{
    &.rowCount4{
      .swiper-button-next{
        right: 10px;
        left: auto;
        bottom: 80px;
        margin-top: 15%; 
        @media all and (max-width:2600px){
          margin-top: 17%; 
        } 
        @media all and (max-width:2200px){
          margin-top: 16%; 
        } 
        @media all and (max-width:768px){
          margin-top: 7%;
        }
        @media all and (max-width:640px){
          margin-top: 11%;
        }
        @media all and (max-width:480px){
          margin-top: 17%;
        }
        @media all and (max-width:360px){
          margin-top: 26%;
        }
      }
      .swiper-button-prev{
        margin-top: 60px;
        left: -7%;
        right: 100px;
        margin-top: 15%;
        @media all and (max-width:2600px){
          margin-top: 17%;
          margin-left: 3%;
        }
        @media all and (max-width:2200px){
          margin-top: 16%; 
          margin-left: 2.5%;
        }
        @media all and (max-width:1980px){
          margin-left: 2%;
        }
        @media all and (max-width:1768px){
          margin-left: 0%;
        }
        @media all and (max-width:1200px){
          margin-left: -1%;
        }
        @media all and (max-width:980px){
          margin-left: -3%;
        }
        @media all and (max-width:768px){
          margin-left: -7%;
          margin-top: 7%;
        }
        @media all and (max-width:640px){
          margin-left: -10%;
          margin-top: 11%;
        }
        @media all and (max-width:480px){
          margin-left: -16%;
          margin-top: 17%;
        }
        @media all and (max-width:360px){
          margin-left: -22%;
          margin-top: 26%;
        }
        
        
        
       
      }
     }
    &.rowCount5{
      .swiper-button-next{
        right: 10px;
        left: auto;
        margin-top: 12%;
        @media all and (max-width:2600px){
          margin-top: 14%;
        }
        @media all and (max-width:2200px){
          margin-top: 13%;
        }
        @media all and (max-width:1980px){
          margin-top: 12%; 
        }
        @media all and (max-width:1400px){
          margin-top: 12%;
        }
        @media all and (max-width:1200px){
          margin-top: 11%;
        }
        @media all and (max-width:980px){
          margin-top: 10%;
        }
        @media all and (max-width:768px){
          margin-top: 7%;
        }
        @media all and (max-width:640px){
          margin-top: 12%;
        }
        @media all and (max-width:480px){
          margin-top: 15%;
        }
        @media all and (max-width:360px){
          margin-top: 26%;
        } 
      }
      .swiper-button-prev{
        margin-top: 12%;
        left: -7%;
        right: 100px;
        bottom: 60px;
        @media all and (max-width:2600px){
          margin-top: 14%;
          margin-left: 3%;
        }
        @media all and (max-width:2200px){
          margin-top: 13%;
          margin-left: 2%;
        }
        @media all and (max-width:1980px){
          margin-top: 12%;
          margin-left: 2%;
        }
        @media all and (max-width:1768px){
          margin-left: 1%;
        }
        @media all and (max-width:1600px){
          margin-left: 0%;
        }
        @media all and (max-width:1400px){
          margin-top: 12%;
        }
        @media all and (max-width:1200px){
          margin-top: 11%;
          margin-left: -2%;
        }
        @media all and (max-width:980px){
          margin-top: 10%;
          margin-left: -4%;
        }
        @media all and (max-width:768px){
          margin-left: -7%;
          margin-top: 7%;
        }
        @media all and (max-width:640px){
          margin-top: 12%;
          margin-left: -10%;
        }
        @media all and (max-width:480px){
          margin-top: 15%;
          margin-left: -15%;
        }
        @media all and (max-width:360px){
          margin-top: 26%;
          margin-left: -22%;
        }
      }
     }
    &.rowCount6{
      .swiper-button-next{
        right: 10px;
        left: auto;
        bottom: 80px;
        margin-top: 15%; 
        @media all and (max-width:2600px){
          margin-top: 11%; 
        }
        @media all and (max-width:1980px){
          margin-top: 10%; 
        } 
        @media all and (max-width:1200px){
          margin-top: 9%; 
          margin-right: -0.5%;
        }
        @media all and (max-width:980px){
          margin-top: 8%;
          margin-right: -1%;
        } 
        @media all and (max-width:768px){
          margin-top: 7%;
        }
        @media all and (max-width:640px){
          margin-top: 11%;
        }
        @media all and (max-width:480px){
          margin-top: 16%;
        }
        @media all and (max-width:360px){
          margin-top: 25%;
        }
      }
      .swiper-button-prev{
        margin-top: 60px;
        left: -7%;
        right: 100px;
        margin-top: 15%;
        @media all and (max-width:2600px){
          margin-top: 11%;
          margin-left: 3%;
        }
        @media all and (max-width:2200px){
          margin-left: 2.5%;
        }
        @media all and (max-width:1980px){
          margin-top: 10%;
          margin-left: 2%;
        }
        @media all and (max-width:1768px){
          margin-left: 1%;
        }
        @media all and (max-width:1600px){
          margin-left: 0.5%;
        }
        @media all and (max-width:1400px){
          margin-left: -0.5%;
        }
        @media all and (max-width:1200px){
          margin-top: 9%;
          margin-left: -2%;
        }
        @media all and (max-width:980px){
          margin-top: 8%;
          margin-left: -4%;
        }
        @media all and (max-width:768px){
          margin-top: 7%;
          margin-left: -7%;
        }
        @media all and (max-width:640px){
          margin-top: 11%;
          margin-left: -9%;
        }
        @media all and (max-width:480px){
          margin-top: 16%;
          margin-left: -15%;
        }
        @media all and (max-width:360px){
          margin-top: 26%;
          margin-left: -22%;
        }  
        
      }
     }
    
  }
  .landscape{
   
    &.rowCount4{
      .swiper-button-next{
        right: 10px;
        left: auto;
        bottom: 80px;
        margin-top: 60px;
        @media all and (max-width:2600px){
          margin-top: 125px;
        }
        @media all and (max-width:2500px){
          margin-top: 125px;
        }
        @media all and (max-width:2200px){
          margin-top: 110px;
        }
        @media all and (max-width:1980px){
          margin-top: 90px;
        }
        @media all and (max-width:1768px){
          margin-top: 71px;
        }
        @media all and (max-width:1600px){
          margin-top: 65px;
        }
        @media all and (max-width:1400px){
          margin-top: 50px;
        }
        @media all and (max-width:1200px){
          margin-top: 38px;
        }
        @media all and (max-width:980px){
          margin-top: 23px;
        }
        @media all and (max-width:768px){
          margin-top: 10px;
        }
        @media all and (max-width:640px){
          margin-top: 0px;
        }
        @media all and (max-width:480px){
          margin-top: 20px;
        }
        @media all and (max-width:360px){
          margin-top: 60px;
        }
      }
      .swiper-button-prev{
        margin-top: 60px;
        left: 10px;
        right: 100px;
        bottom: 60px;
        @media all and (max-width:2600px){
          margin-top: 140px;
          margin-left: -110px;
        }
        @media all and (max-width:2500px){
          margin-top: 125px;
        }
        @media all and (max-width:2200px){
          margin-top: 110px;
        }
        @media all and (max-width:1980px){
          margin-top: 90px;
        }
        @media all and (max-width:1768px){
          margin-top: 75px;
        }
        @media all and (max-width:1600px){
          margin-top: 71px;
        }
        @media all and (max-width:1400px){
          margin-top: 55px;
        }
        @media all and (max-width:1200px){
          margin-top: 40px;
        }
        @media all and (max-width:980px){
          margin-top: 18px;
        }
        @media all and (max-width:768px){
          margin-top: 10px;
        }
        @media all and (max-width:640px){
          margin-top: 0px;
        }
        @media all and (max-width:480px){
          margin-top: 20px;
        }
        @media all and (max-width:360px){
          margin-top: 60px;
        }
    
      }

    }
    &.rowCount5{
      .swiper-button-next{
        right: 10px;
        left: auto;
        margin-top: 3%;
        @media all and (max-width:2600px){
          margin-top: 4%;
        }
        @media all and (max-width:1980px){
          margin-top: 3%; 
        }
        @media all and (max-width:1400px){
          margin-top: 2.5%;
        }
        @media all and (max-width:1200px){
          margin-top: 2%;
        }
        @media all and (max-width:980px){
          margin-top: 1%;
        }
        @media all and (max-width:768px){
          margin-top: 4%;
        }
        @media all and (max-width:640px){
          margin-top: 0%;
        }
        @media all and (max-width:480px){
          margin-top: 5%;
        }
        @media all and (max-width:360px){
          margin-top: 16%;
        } 
      }
      .swiper-button-prev{
        margin-top: 3%;
        left: -7%;
        right: 100px;
        bottom: 60px;
        @media all and (max-width:2600px){
          margin-top: 4%;
          margin-left: 3%;
        }
        @media all and (max-width:1980px){
          margin-top: 3%;
          margin-left: 2%;
        }
        @media all and (max-width:1768px){
          margin-left: 1%;
        }
        @media all and (max-width:1400px){
          margin-left: 0%;
          margin-top: 2.5%;
        }
        @media all and (max-width:1200px){
          margin-top: 2%;
          margin-left: -2%;
        }
        @media all and (max-width:980px){
          margin-top: 1%;
          margin-left: -4%;
        }
        @media all and (max-width:768px){
          margin-left: -7%;
          margin-top: 4%;
        }
        @media all and (max-width:640px){
          margin-top: 0%;
          margin-left: -10%;
        }
        @media all and (max-width:480px){
          margin-top: 5%;
          margin-left: -15%;
        }
        @media all and (max-width:360px){
          margin-top: 16%;
          margin-left: -22%;
        }
      }
    }
    &.rowCount6{
      .swiper-button-next{
        right: 10px;
        left: auto;
        bottom: 80px;
        margin-top: 2%;

       
        @media all and (max-width:2600px){
          margin-top: 3%;
        }
        @media all and (max-width:1768px){
          margin-top: 2%;
        }
       
        @media all and (max-width:1200px){
          margin-top: 1%;
        }
        @media all and (max-width:980px){
          margin-top: 0%;
        }
        @media all and (max-width:768px){
          margin-top: 3%;
        }
        @media all and (max-width:640px){
          margin-top: 5%;
          margin-left: -10%;
        }
        @media all and (max-width:360px){
          margin-top: 14%;
          margin-left: -22%;
        }
      }
      .swiper-button-prev{
        margin-top: 2%;
        left: -7%;
        right: 100px;
        bottom: 60px;
        
        @media all and (max-width:2600px){
          margin-top: 3%;
          margin-left: 3%;
        }
        @media all and (max-width:1768px){
          margin-top: 2%;
        }
        @media all and (max-width:1600px){
          margin-left: -1%;
        }
        @media all and (max-width:1200px){
          margin-left: -2%;
          margin-top: 1%;
         }
        
        @media all and (max-width:980px){
          margin-top: 0%;
        }
        @media all and (max-width:768px){
          margin-top: 3%;
          margin-left: -7%;
        }
        @media all and (max-width:640px){
          margin-top: 5%;
          margin-left: -15%;
        }
        @media all and (max-width:360px){
          margin-top: 14%;
        }
    
      }
    }
  }
}

div.showsRow.ltr{
  .portrait{
    direction: ltr;
    text-align: left;
  }
  .landscape{
    direction: ltr;
    text-align: left;
  }
}
