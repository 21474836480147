div.newsCard { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--bg-color);
    transition: 0.4s ease-in-out;
    &:hover{
        background-color: var(--secondary-bg-color);
    }
    @media all and (max-width:980px){
        flex-direction: column;
    }
	div.left { 
        width: 15%;
        @media all and (max-width:1200px){
            width: 50%;
        }
        @media all and (max-width:980px){
            width: 100%;
            margin-bottom: 20px;
        }
		div.imageContainer { 
            width: 100%;
			img { 
                width: 100%;
                display: block;
                border-radius: 8px;
			}
		}
	}

	div.right { 
        width: 80%;
        @media all and (max-width:980px){
            width: 100%;
        }
		h1.title { 
            font-size: 22px;
            margin-bottom: 20px;
            color: var(--white-color);
            @media all and (max-width:1200px){
                margin-bottom: 10px;
            }
            @media all and (max-width:640px){
                font-size: 20px;
            }
		}

		p { 
            font-size: 16px;
            color: var(--text-color);
            margin-bottom: 10px;
            @media all and (max-width:1200px){
                    font-size: 14px;
                    margin-bottom: 10px;
            }
            @media all and (max-width:640px){
                font-size: 13px;
            }
		}

		div.dateSection { 
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            @media all and (max-width:1200px){
                margin-bottom: 10px;
        }
			span.icon { 
                width: 20px;
                display: inline-block;
                margin-inline-end: 10px;
				img { 
                    width: 100%;
                    display: block;
				}
			}

			span.date { 
                font-size: 14px;
                color: var(--text-hover);
                
                @media all and (max-width:640px){
                    font-size: 13px;
                }
			}
		}

		div.buttonContainer { 
            display: flex;
        padding: 12px 20px;
        border-radius: 4px;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        justify-content: center;
        position: relative;
        width: 15rem;
        margin: 20px 0 0;
        @media all and (max-width:768px){
            margin: 20px 0 0;
        }
        @media all and (max-width:640px){
            width: 12rem;
        }
        @media all and (max-width:480px){
            width: 15rem;
        }
        &:hover{
            div.background{

                transform: scale(1.0476190476,1.0357142857);
            }
        }
            div.background{
                background: var(--text-hover);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
                transition: 0.1s ease-in-out;
                z-index: 1;

            }
            button { 
                background: transparent;
                font-size: 15px;
                color: var( --white-color);
                font-weight: 600;
                position: relative;
                z-index: 2;
                cursor: pointer;


            }
        }
	}
}
