div.livePlayer {
    width: 100%;
    // padding: 120px 0 20px;
    div.videoContainer {
      width: 100%;
      position: relative;
      padding: 50px 0 0px;
    //   top: 50px;
    
    
      video {
        display: block;
        width: 100%;
      }
      // @media all and (min-width:768px){
      //     &:hover {
      //       div.bottom {
      //         div.details {
      //           transform: translateY(0);
                
      //         }
      //       }
      //     }
      //   }
      div.bottom {
        width: 90%;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        height: 200px;
        overflow: hidden;
        @media all and (max-width:768px){
          position: relative;
          bottom: unset;
          inset-inline-start: unset;
          transform: unset;
          width: 100%;
          height: auto;
      }
        @media(min-width: 768px){
          &:hover {
            div.details {
              transform: translateY(0);
            }
          }
        }
  
        div.details {
          display: flex;
          justify-content: space-between;
          height: 100%;
          transform: translateY(200px);
          transition: 0.3s ease-in-out;
          background-color: rgba(41,36,35,0.6);
          padding: 20px;
          border-radius: 8px;
          @media all and (max-width:768px){
             flex-direction: column;
             transform: unset;
          }
         
  
          h1.heading {
            font-size: 28px;
            color: var(--white-color);
            margin-bottom: 10px;
            @media all and (max-width:768px){
              font-size: 22px;
           }
          }
          h1.title {
            font-size: 20px;
            // color: var(--text-hover);
            color: var(--yellow-color);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          //   width: 100%;
        
          }
          span.time{
              font-size: 14px;
              color: var(--text-color);
              display: inline-block;
              margin-bottom: 10px;
          }
          p.description {
            color: var(--white-color);
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            // -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-line-clamp: 3;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          div.left {
            width: 48%;
            height: 100%;
            @media all and (max-width:768px){
              width: 100%;
              margin-bottom: 20px;
           }
          }
          div.right {
            width: 48%;
            height: 100%;
            @media all and (max-width:768px){
              width: 100%;
           }
          }
        }
      }
    }
  }
