div.header-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: var(--bg-color);
  height: 50px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  border-bottom: 1px solid var(--border-color);
  // &.background {
  //   background: transparent;

  // }
  div.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    div.leftContainer {
      display: flex;
      align-items: center;
      width: 36%;
      @media all and (max-width: 1200px) {
        width: 70%;
      }
      @media all and (max-width: 980px) {
        width: 49%;
      }
      @media all and (max-width: 768px) {
        width: 26%;
      }
      div.inputContainer {
        padding: 12px 15px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        transition: 0.2s ease-in-out;
        margin-inline-end: 30px;
        cursor: pointer;
        border-inline-start: 1px solid var(--border-color);
        margin-inline-start: 10px;
        position: relative;
        &.showInput {
          background-color: #f6f6f6;
        }
        @media all and (max-width: 1200px) {
          &.showInput {
            width: 70%;
            input {
              display: block;
            }
          }
        }
        @media all and (max-width: 980px) {
          width: 83%;
          justify-content: center;
          padding: 10px 8px;
          &.showInput {
            width: 57%;
            justify-content: space-between;
          }
        }
        @media all and (max-width: 768px) {
          display: none;
        }
        &:hover {
          border-color: #c5c5c8;
        }
        svg {
          width: 18px !important;
          height: 18px !important;
          color: var(--grey-color) !important;
          margin-inline-end: 6px;
          // @media all and (max-width: 1200px) {
          //   margin-right: 0;
          // }
        }

        div.searchSuggestionsContainer {
          position: absolute;
          top: 47px;
          z-index: 9;
          background-color: var(--bg-color);
          padding: 15px;
          width: 100%;
          inset-inline-start: 0;
          cursor: default;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

          @media all and (max-width:768px){
            display: none;
          }
          h6.heading {
            font-size: 12px;
            color: var(--grey-color);
            margin-bottom: 10px;
            span.count {
              font-weight: normal;
            }
          }
          ul.lists {
            div.categorySection{
              margin-bottom: 20px;
              h6.heading {
                font-size: 12px;
                color: var(--text-color);
                margin-bottom: 10px;
                span.count {
                  font-weight: normal;
                }
              }
              li.list {
                display: flex;
                margin-bottom: 3px;
                cursor: pointer;
                div.listContainer{
                  
                    display: flex;
                    div.left {
                      margin-inline-end: 10px;
                      div.imageContainer {
                        width: 50px;
                        height: 50px;
                        img {
                          width: 100%;
                          height: 100%;
                          display: block;
                          object-fit: cover;
                        }
                      }
                    }
                    div.right {
                      h1.title {
                        font-size: 14px;
                        color: var(--text-color);
      
                      }
                      span.director {
                        font-size: 12px;
                        color: var(--grey-color);
                      }
                    }
                  
                 
                }
               
              }
            }
          }
          div.seeAll {
            text-align: center;
            border-top: 1px solid #eaeaea;
            padding-top: 10px;
            margin-top: 20px;
            cursor: pointer;
            button {
              background-color: transparent;
              font-size: 14px;
              color: var(--text-color);
              font-weight: 700;
            cursor: pointer;

            }
          }
        }

        div.closeIcon{
          position: absolute;
          inset-inline-end: 5px;
          svg{
            width: 16px !important;
            height: 16px !important;
          }
        }

        div.searchLoading {
          position: absolute;
          inset-inline-end: 30px;
          bottom: 30px;
        }

        input {
          border: none;
          outline: none;
          width: 85%;
          margin-inline-end: auto;
          background-color: transparent;
          font-size: 13px;
          color: var(--grey-color);
          caret-color: var(--grey-color);
          // @media all and (max-width: 1200px) {
          //   display: none;
          // }
          &::placeholder {
            color: #cac9c8;
            font-size: 13px;
            transition: 0.3s ease-in-out;
          }
        }
      }
      div.logoContainer {
        h1 {
          width: 120px;
          max-height: 28px;
          display: flex;
          align-items: center;
          @media all and (max-width: 480px) {
            width: 91px;
          }
          @media all and (max-width: 360px) {
            width: 80px;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            aspect-ratio: 3;
            object-fit: contain;
          }
        }
      }
      ul.hide {
        @media all and (max-width: 1200px) {
          &.hide {
            display: none;
          }
        }
      }
    }

    div.rightContainer {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      justify-content: end;
      width: 50%;
      transition: 0.2s ease-in-out;
      position: relative;
      @media all and (max-width: 1200px) {
        width: 40%;
      }
      @media all and (max-width: 1200px) {
        width: 40%;
      }
      @media all and (max-width: 768px) {
        width: 80%;
      }
      @media all and (max-width: 360px) {
        width: 80%;
      }
      div.languageDropdown {
        position: relative;
        cursor: pointer;
        display: inline-block;
        // font-size: 12px;
        font-size: 14px;
        color: white;
        // padding: 15px 0;
        padding: 13px 0;
        padding-inline-end: 10px;
        // transition: color 0.3s ease, font-weight 0.3s ease;
        @media(max-width: 768px){
          display: none;
        }
      
        &:hover {
          // color: var(--text-hover);
          color: gray;
        }
      
        div.language {
          // font-size: 12px;
          font-size: 14px;
          font-weight: bold;
          // font-weight: normal;
          transition: color 0.3s ease, font-weight 0.3s ease;
        }
      
        div.languageOptions {
          position: absolute;
          top: 100%;
          inset-inline-start: 0;
          min-width: 210px;
          background-color: var(--bg-color);
          padding-top: 15px;
          padding-bottom: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 100;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 4px; 
            background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
            // background: linear-gradient(281deg, var(--button-color), var(--button-hover));
          }
      
          div.optionsList {
            display: flex;
            flex-direction: column;
      
            div.languageOption {
              // font-size: 14px;
              font-size: 16px;
              padding: 8px 12px;
              color: var(--text-color);
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              white-space: nowrap;

              &[data-selected="true"] {
                color: var(--gradient-left-color);
                // color: var(--button-color);
                
                &:after {
                  content: "✔";
                  margin-inline-start: auto;
                }
              }
      
              &:hover {
                color: var(--gradient-left-color);
                // color: gray;
              }
            }
          }
        }
      }
      div.language{
        cursor: pointer;
        @media all and (max-width: 480px) {
          display: none;
        }
        i{
          // font-size: 12px;
          font-size: 14px;
          font-weight: 900;
          transition: 0.3s ease-in-out;
          color: var(--text-color);
          margin-inline-end: 6px;
          @media(max-width: 480px){
            font-size: 12px;
          }
          @media all and (max-width: 360px) {
            font-size: 10px;
          }
        }
        span{
          // font-size: 12px;
          font-size: 14px;
            font-weight: 900;
            transition: 0.3s ease-in-out;
            color: var(--text-color);
            text-transform: uppercase;
            @media(max-width: 480px){
              font-size: 12px;
            }
            @media all and (max-width: 360px) {
              font-size: 10px;
            }
        }
      }
      div.liveButtonContainer{
        position: relative;
        align-items: center;
        ul.liveButton{
          background-color: #f88d0c;
          border-radius: 5px;   
          padding: 5px 5px 5px 5px;
          text-transform: uppercase;
          margin-inline-end: 20px;
          letter-spacing: 1px;
          animation: blink 0.5s infinite;
          cursor: pointer;
          text-align: center;
          display: block;
          margin: 0 auto;
          li{
            
              color: white !important;
             
              // height: 15px;
              // font-size: 12px;
              font-size: 14px;
              font-weight: 900;
              @media(max-width: 480px){
                font-size: 12px;
              }
              @media all and (max-width: 360px) {
                font-size: 10px;
              }
          }
          
          @keyframes blink {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
     
      ul.browse {
        border-inline-end: 1px solid var(--border-color);
        padding: 13px 0;
        li {
          cursor: pointer;
          padding: 0 30px;
          @media all and (max-width: 360px) {
            padding: 0 10px;
          }
          span {
            // font-size: 12px;
            font-size: 14px;
            font-weight: 900;
            transition: 0.3s ease-in-out;
            color: var(--text-color);
            @media(max-width: 480px){
              font-size: 12px;
            }
            @media all and (max-width: 360px) {
              font-size: 10px;
            }
          }     
          &:hover {
            span {
              color: var(--text-hover);
            }
          }
          &.active {
            span {
              color: var(--text-color);
            }
          }
          &.no-active {
            span {
              color: var(--text-color);
            }
          }
        }
      }      
      ul.with-bg {
        border-inline-end: 1px solid var(--grey-color);
        li {
          &:hover {
            span {
              color: gray;
            }
          }
        }
      }
      h4 {
        color: var(--text-color);
        // font-size: 12px;
        font-size: 14px;
        margin-inline-start: 10px;
        @media(max-width: 480px){
          font-size: 12px;
        }
        @media all and (max-width: 360px) {
          font-size: 10px;
        }
      }

      div.buttonContainer {
        margin-inline-start: 30px;
        @media all and (max-width: 480px) {
          margin-inline-start: 15px;
        }
        @media all and (max-width: 360px) {
          margin-inline-start: 7px;
        }
        button.signIn {
          background-color: transparent;
          font-weight: 700;
          // font-size: 12px;
          font-size: 14px;
          color: var(--text-color);
          cursor: pointer;
          @media all and (max-width: 480px) {
            font-size: 12px;
          }
          @media all and (max-width: 360px) {
            font-size: 10px;
          }
        }
      }
      div.menuIconContainer {
        line-height: 0;
        margin-inline-start: 20px;
        @media all and (max-width: 360px) {
          margin-inline-start: 5px;
        }
        svg {
          width: 18px !important;
          height: 18px !important;
          color: var(--text-color);
          cursor: pointer;
        }
      }
      div.menuListContainer {
        width: 10rem;
        position: absolute;
        inset-inline-end: 0;
        background-color: var(--bg-color);
        top: 60px;
        padding: 10px 18px 10px 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        &::after {
          content: "";
          position: absolute;
          top: -10px;
          /* width: 7px; */
          /* height: 4px; */
          border-color: transparent transparent var(--bg-color);
          border-style: solid;
          border-width: 1px 9px 16px;
          /* right: 2%; */
          /* transform: translateX(-580%); */
          inset-inline-end: 4px;
        }
        ul {
          a{
            cursor: pointer;
          }
          li {
            cursor: pointer;
            margin-bottom: 6px;
            // &:last-child{
            //   margin-bottom: 0;
            // }
            span {
              font-size: 14px;
              color: var(--text-color);
              letter-spacing: .2px;
            }
            &:hover {
              span {
                color: var(--text-hover);
              }
            }
            &.bg-set:hover {
              span {
                color:var(--grey-color); 
              }
            }
          
            &.bg-not-set:hover {
              span {
                color:  var(--text-hover);
              }
            }
          }
        }
      }
    }
  }
}
div.userInfoContainer {
  position: fixed;
  inset-inline-end: 30px;
  width: 20rem;
  top: 15%;
  background-color: rgb(37, 42, 52);
  border-radius: 8px;
  display: none;
  z-index: 9999;
  &.active {
    display: block;
  }
  @media all and (max-width: 1200px) {
    top: 15%;
  }
  @media all and (max-width: 640px) {
    width: 84%;
  }
  div.top {
    padding: 20px 20px 0;

    ul {
      li {
        border-bottom: 1px solid var(--border-color);
        padding: 15px 0;
        cursor: pointer;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
        span {
          color: var(--white-color);
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
  }
  div.bottom {
    background: linear-gradient(180deg, #10141f, rgba(16, 20, 31, 0));
    padding: 15px;
    div.signOutContainer {
      display: flex;
      align-items: center;
      padding: 12px;
      background-color: var(--border-color);
      border-radius: 8px;
      cursor: pointer;
      svg {
        color: var(--white-color);
        width: 22px !important;
        height: 22px !important;
        margin-inline-end: 10px;
      }
      span {
        color: var(--white-color);
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}
div.overlayOne {
  &.active {
    @media all and (max-width: 768px) {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--overlay);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.3s ease-in-out;
    }
  }
}

div.overlayOne.active {
  display: flex;
}
div.browseMore {
  @media all and (max-width: 768px) {
    display: none;
  }
  //  animation: fadeIn 0.3s ease-in-out ;
  display: none;

  div.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  div.browseMoreContainer {
    animation: fadeIn 0.7s ease-in-out;

    position: fixed;
    // width: 70%;
    top: 15%;
    inset-inline-end: 8%;
    // transform: translateX(-50%);
    background-color: var(--bg-color);
    display: flex;
    border-radius: 6px;
    z-index: 999;
    &::after {
      content: "";
      top: -10px;
      /* width: 7px; */
      /* height: 4px; */
      border-color: transparent transparent var(--bg-color);
      border-style: solid;
      border-width: 0px 9px 15px;
      position: absolute;
      inset-inline-end: 40px;
      @media all and (max-width: 1768px) {
        inset-inline-end: 60px;
      }
      @media all and (max-width: 1600px) {
        inset-inline-end: 70px;
      }
    }
    @media all and (max-width: 2500px) {
      top: 5%;
      inset-inline-end: 6%;
    }
    @media all and (max-width: 1980px) {
      top: 6%;
    }
    @media all and (max-width: 1600px) {
      top: 7%;
    }
    @media all and (max-width: 1400px) {
      top: 10%;
    }

    h1.categoryName {
      font-size: 14px;
      margin-bottom: 20px;
      color: var(--grey-color);
    }
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      li {
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          span {
            color: var(--text-hover);
          }
        }
        span {
          font-size: 14px;
          color: #10141f;
          transition: 0.3s ease-in-out;
        }
      }
    }
    div.left {
      // width: 22%;
      padding: 30px 25px;
      overflow-y: scroll;
      height: 500px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(125, 128, 128);
      }
    }
    div.center {
      width: 40%;
      background-color: #f0f3f5;
      padding: 30px 25px;
      div.sections {
        display: flex;
        justify-content: space-between;
        div.leftSection {
          width: 50%;
        }
        div.rightSection {
          width: 50%;
        }
      }
    }
    div.right {
      width: 40%;
      padding: 30px 25px;
    }
  }
}
div.browseMore.active {
  display: block;
  @media all and (max-width: 768px) {
    display: none;
  }
  // animation: fadeIn 0.4s ease-in-out ;
}

div.browseMoreResponsive {
  div.searchArea {
    display: none;
    z-index: 99999;
    position: fixed;
    width: 100%;
    background: #fff;
    padding: 15px;
    top: 0;
    inset-inline-start: -50%;
    border-bottom: 1px solid var(--border-color);
    transition: 0.5s ease-in-out;

    @media all and (max-width: 768px) {
      &.active {
        inset-inline-start: 0;
      }
      display: block;
      div.inputContainer {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        position: relative;
       
        input {
          display: block;
          outline: none;
          border: none;
          background-color: transparent;
          width: 93%;
          color: var(--text-color);
          font-size: 16px;
        }
        svg {
          margin-inline-end: 10px;
        }
      }
      
    }
    div.closeIcon{
      position: absolute;
      inset-inline-end: 5px;
      top: 30%;
      display: none;
      @media all and (max-width: 768px){
        display: block;
      }
      svg{
        width: 16px !important;
        height: 16px !important;
      }
    }

    div.searchLoading {
      position: absolute;
      inset-inline-end: 30px;
      bottom: 30px;
      display: none;
      @media all and (max-width: 768px){
        display: block;
      }
    }
     div.searchSuggestionsContainerMedium {
        position: absolute;
        top: 47px;
        z-index: 99999;
        background-color: var(--white-color);
        padding: 15px;
        width: 100%;
        inset-inline-start: 0;
        cursor: default;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: none;
      @media all and (max-width: 768px){
        display: block;
      }
        h6.heading {
          font-size: 12px;
          color: var(--grey-color);
          margin-bottom: 10px;
          span.count {
            font-weight: normal;
          }
        }
        ul.lists {
          li.list {
            display: flex;
            margin-bottom: 3px;
            cursor: pointer;
            div.left {
              margin-inline-end: 10px;
              div.imageContainer {
                width: 50px;
                height: 50px;
                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                }
              }
            }
            div.right {
              h1.title {
                font-size: 14px;
                color: var(--black-color);
              }
              span.director {
                font-size: 12px;
                color: var(--grey-color);
              }
            }
          }
        }
        div.seeAll {
          text-align: center;
          border-top: 1px solid #eaeaea;
          padding: 10px 0;
          margin-top: 20px;
          cursor: pointer;
          background-color: var(--secondary-bg-color);
          button {
            background-color: transparent;
            font-size: 14px;
            color: var(--text-color);
            font-weight: 700;
          cursor: pointer;

          }
        }
      }
  }
  div.container {
    position: fixed;
    inset-inline-start: -50%;
    width: 35%;
    background-color: var(--bg-color);
    padding: 70px 30px 35px;
    z-index: 9999;
    top: 0;
    bottom: 0;
    overflow: scroll;
    transition: 0.5s ease-in-out;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media all and (max-width: 768px) {
      &.active {
        inset-inline-start: 0;
      }
    }
    @media all and (max-width: 980px) {
      width: 45%;
    }
    @media all and (max-width: 768px) {
      width: 100%;
      inset-inline-start: -100%;
    }
    div.language{
      cursor: pointer;
      display: none;
      @media all and (max-width: 480px) {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        align-items: center;
      }
      i{
        font-size: 12px;
        font-weight: 900;
        transition: 0.3s ease-in-out;
        color: var(--text-color);
        margin-inline-end: 6px;
      }
      span{
        font-size: 12px;
          font-weight: 900;
          transition: 0.3s ease-in-out;
          color: var(--text-color);
          text-transform: uppercase;
      }
    }
    div.head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div.left {
        h1 {
          color: var(--text-hover);
          font-size: 15px;
          font-weight: 700;
        }
      }
      div.right {
        svg {
          color: var(--text-color);
          width: 23px !important;
          height: 23px !important;
        }
      }
    }

    div.top {
      margin-top: 50px;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--border-color);

      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: var(--text-color);
          }
          &:hover {
            span {
              color: var(--text-hover);
            }
          }
        }
      }
    }
    div.center {
      padding: 30px 0;
      border-bottom: 1px solid var(--border-color);
      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: var(--text-color);
          }
        }
      }
    }
    div.bottom {
      padding: 30px 0;
      h1.categoryName {
        font-size: 16px;
        margin-bottom: 20px;
        color: var(--grey-color);
        font-weight: 700;
      }
      ul {
        li {
          margin-bottom: 8px;
          span {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
        div.languageDropdown {
      position: relative;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      color: white;
      padding: 15px 0;
      // transition: color 0.3s ease, font-weight 0.3s ease;
    
      &:hover {
        // color: var(--text-hover);
        color: gray;
      }
    
      h1.language {
        font-size: 16px;
        font-weight: bold;
        transition: color 0.3s ease, font-weight 0.3s ease;
      }
    
      div.languageOptions {
        position: absolute;
        top: 100%;
        inset-inline-start: 0;
        min-width: 210px;
        background-color: var(--bg-color);
        padding-top: 15px;
        padding-bottom: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 100;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          inset-inline-start: 0;
          width: 100%;
          height: 4px; 
          background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
          // background: linear-gradient(281deg, var(--button-color), var(--button-hover));
        }
    
        div.optionsList {
          display: flex;
          flex-direction: column;
    
          div.languageOption {
            font-size: 16px;
            padding: 8px 12px;
            color: var(--text-color);
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;

            &[data-selected="true"] {
              color: var(--gradient-left-color);
              // color: var(--button-color);
              
              &:after {
                content: "✔";
                margin-inline-start: auto;
              }
            }
    
            &:hover {
              color: var(--gradient-left-color);
              // color: gray;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
