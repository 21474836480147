div.jwmAboutUs {
    padding: 130px 0;
    background-color: var(--secondary-bg-color);

div.wrapper {
div.content-container {
    gap: 20px;
    display: flex;
    width: 60%;
    margin: 0px auto;
    padding: 100px 20px 0px;
    
    @media(max-width: 980px){
        flex-direction: column;
        width: 90%;
        padding: 50px 20px 0px;
        align-items: center;
        text-align: center;
    }

div.logo {
    width: 25%;
    flex-shrink: 0;
img {
    width: 100%;
    height: auto;
    display: block;
}
}

div.about-content {
    font-size: 20px;
    font-weight: medium;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(max-width: 980px){
        font-size: 16px;
    }
    a{
        font-size: 18px;
        align-self: start;
        margin-bottom: 5px;
        color: #007BFF;
        &:hover{
            color: rgb(80, 105, 132)
        }

        @media(max-width: 980px){
            align-self: center;
            font-size: 16px;
        }
    }
    div.bottomContainer{
        align-self: start;
        div.stores{
            display: flex;
            flex-direction: row;
            gap: 20px;

            @media(max-width: 980px){
                justify-content: center;
            }
            img{
                width: 10%;
                border-radius: 20%;
                cursor: pointer;

                @media(max-width: 480px){
                    width: 20%;
                }
            }
            svg{
                width: 15%;
                cursor: pointer;

                @media(max-width: 480px){
                    width: 35%;
                }
            }
        }
    }
}
}
}
}