div.categoriesContainer{
    border-bottom: 1px solid var(--border-color);
    margin-top: 20px;

    @media all and (max-width:1200px){
        width: 98%;
        margin-inline-start: auto;
        margin-top: 20px;
        .swiper-button-next,.swiper-button-prev{
            display: none;
        }
    }
    @media all and (max-width: 480px) {
        width: 81%;
     margin-inline-start: 0; 
    position: relative;
    inset-inline-start: 20px;
      }
      .swiper-slide {
        z-index: 9;
        &:hover {
            z-index: 99!important;      
        }
      }
    &:hover{
       
        .swiper-button-next{
            &::after{
                opacity:1;
            }
        }
        .swiper-button-prev{
            &::after{
                opacity:1;
            }
        }
    }
    .swiper-button-next{
        &::after{
            
        }
    }
    .swiper-button-prev{
        // &::after{
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
        //     @media all and (min-width:320px){
               
        //         font-size: 9px;
        //         width: 10px;
        //         height: 10px;
            
        //     }
        //     @media all and (min-width:980px){
               
        //         font-size: 18px;
        //         width: 20px;
        //         height: 20px;
            
        //      }
        // }
        inset-inline-start: -112px;
    }
    .swiper-button-disabled {
        display: none !important;
      }

    div.wrapper{
        &:hover{
            div.itemsContainer{

                div.categoryNameContainer{
                    span.seeAll{
                        visibility: visible;
                    }
                }
            }
        }
    }
    div.categoryNameContainerMedium{
        display: none;
        @media all and (max-width:1200px){
            display:block;
            h1{
                font-size: 11px;
                text-transform: uppercase;
                color: var(--text-color);
            }
        }
    }
    
    div.itemsContainer{
        position: relative;
        margin-top: 20px;
        width: 90%;
        margin-inline-start: auto;
        z-index: 999;
        @media all and (max-width:1200px){
            margin-inline-start: 0;
            width: 100%;
            margin-top: 10px;
        }
        div.categoryNameContainer{
           position: absolute;
           z-index: 9;
        //    inset-inline-start: -122px;
        //    width: 7rem;
        inset-inline-start: -152px;
        width: 9.5rem;
        background-color: var(--bg-color);
        height: -webkit-fill-available;

           cursor: pointer;
        //    @media all and (max-width:1400px){
        //     inset-inline-start: -112px;
        //   }
        @media(max-width: 2600px){
            inset-inline-start: -260px;
            width: 16.2rem;
        }
        @media(max-width: 1980px){
            inset-inline-start: -198px;
            width: 12.4rem;
        }
        @media(max-width: 1600px){
            inset-inline-start: -160px;
            width: 10rem;
        }
        @media(max-width: 1400px){
            inset-inline-start: -152px;
            width: 9.5rem;
        }
          @media all and (max-width:1200px){
            display: none;
          }
          div.categoryDetails{
            margin-inline-start: 20%;
           h1{
            font-size: 12px;
            color: var(--text-color);
            text-transform: uppercase;
            max-width: 99%;
            
           }
           span.seeAll{
            font-size: 11px;
            text-decoration: underline;
            visibility: hidden;
            color: var(--text-color);
           }
        }
        }
        
        div.show{
            width: 14rem;
            @media all and (min-width:320px){
                width: 100%;
            }
            // @media all and (min-width:360px){
            //     width: 11rem;
            // }
            // @media all and (min-width:640px){
            //     width: 13rem;
            // }
            // @media all and (min-width:768px){
            //     width: 13rem;
            // }
            // @media all and (min-width:980px){
            //     width: 13rem;
            // }
        }
    }
}

div.categoriesContainer.ltr{
    direction: ltr;
    text-align: left;
    div.categoryNameContainerMedium{
        direction: rtl;
        text-align: right;
    }
    div.itemsContainer{
        margin-inline-start: unset;
        margin-right: auto;
        @media all and (max-width:1200px){
            margin-inline-start: unset;
            margin-right: 0;
        }
        div.categoryNameContainer{
            direction: rtl;
            text-align: right;
        }
    }
    .swiper-button-next{
        background: none;
    }
    .swiper-button-prev{
        background: rgba(0, 0, 0, 0.5);
        width: 4%;
        left: 0;
        &::after{
            left: unset;
        }
        @media all and (max-width:2500px){
            left: 0;
        }
    }
}
