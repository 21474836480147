.notification-banner {
    position: fixed;
    top: 60px;
    inset-inline-end: 5px;
    width: 90%;
    max-width: 500px;
    z-index: 1000;
    animation: slide-down 0.3s ease-out forwards;
    border-inline-start: 5px solid #5bc0de;
  }
  
  @keyframes slide-down {
    0% {
      transform: translate(0, -100%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  .notification-content {
    background-color: white;
    border-radius: 1px;
    padding: 16px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .icon-container {
    flex-shrink: 0;
    margin-inline-end: 16px;
  }
  
  .info-icon {
    width: 32px;
    height: 32px;
    fill: currentColor;
    color: #5bc0de;
  }
  
  .notification-text {
    flex-grow: 1;
  }
  
  .notification-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
    color: var(--black-color);
  }
  
  .notification-message {
    font-size: 14px;
    color: var(--text-color);
  }
  
  .notification-actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: inherit;
    border-inline-start: 1px solid #e2e0e0;
    @media(max-width: 480px){
        border-inline-start: none;
    }
  }
  
  .action-button {
    cursor: pointer;
    padding: 10px;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    background-color: white;
    
    &.primary {
    font-weight: 700;
    @media(max-width: 480px){
        border-inline-end: 1px solid #e2e0e0;
    }
    }
    
    &.secondary {
    border-top: 1px solid #e2e0e0;
    @media(max-width: 480px){
        border-top: none;
    }
    }
  }

  @media (max-width: 480px) {
    .notification-content {
      flex-direction: column;
      text-align: center;
    }
    
    .icon-container {
      margin-inline-end: 0;
      margin-bottom: 12px;
    }
    
    .notification-actions {
      margin-inline-start: 0;
      margin-top: 12px;
      flex-direction: row;
      justify-content: center;
    }
  }