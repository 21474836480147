div.forgotPassword{
    background: linear-gradient(90deg, rgba(30,38,170,1) 0%, rgba(0,90,137,1) 66%, rgba(106,164,185,1) 100%);
    span.error{
        color: #E72A31;
        font-size: 12px;
       text-align: start;
       display: block;
       margin-bottom: 10px;

    }
    div.forgotPasswordContainer{
    padding: 200px 0;

        h1.heading{
            font-size: 34px;
            color: var(--white-color);
            text-align: center;
            margin-bottom: 20px;
            @media all and (max-width:480px){
                font-size: 26px;
            }
           
        }
        div.formContainer{
            text-align: center;
            width: 35rem;
            margin: 0 auto;
            @media all and (max-width:640px){
                width: 25rem;
            }
            @media all and (max-width:480px){
                width: 100%;
            }
            form{
                input{
                    background: transparent;
                    outline: none;
                    border: 1px solid var(--grey-color);
                    text-align: start;
                    width: 100%;
                padding: 20px 15px;
                caret-color:var(--white-color);
                color: var(--white-color);
                &:focus{
                    border: 1px solid var(--white-color);
                }
                &::placeholder{
                    font-size: 15px;
                    color: rgb(186, 184, 184);
                }
            }
            div.buttonContainer { 
                display: flex;
            padding: 18px 20px;
            align-items: center;

            cursor: pointer;
            transition: 0.2s ease-in-out;
            justify-content: center;
            position: relative;
            width: 20rem;
            margin: 30px auto 0;
            @media all and (max-width:640px){
                width: 15rem;
            }
            @media all and (max-width:480px){
                width: 10rem;
            }
            &:hover{
                div.background{

                    transform: scale(1.0476190476,1.0357142857);
                }
            }
                div.background{
                    background: var(--button-hover);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: 0.1s ease-in-out;
                    z-index: 1;

                }
                button { 
                    background: transparent;
                    font-size: 15px;
                    color: var( --white-color);
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                    cursor: pointer;

                }
            }
            }
        }
    }
}
